import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

import { SectionTitle } from '../utils/GlobalFormatting';

// import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(40)} auto 0;
  padding-bottom: ${rem(80)};
`;

// const Section = styled.div`
//   justify-content: center;
//   /* margin-bottom: ${rem(40)};

//   ${breakpoint('large')} {
//     margin-bottom: ${rem(50)};
//   } */
// `;

// const Department = styled(SectionTitle)`
//   font-size: ${rem(20)};
//   letter-spacing: 0.01em;
//   padding-top: 1.1rem;
//   border-top: 2px solid ${(props) => props.theme.palette.colors.sixt};

//   ${breakpoint('mediumlarge')} {
//     font-size: ${rem(22)};
//   }

//   ${breakpoint('large')} {
//     font-size: ${rem(24)};
//   }
// `;

// const StaffCol = styled(Col)`
//   margin: 0 0 ${rem(30)};
//   width: 100%;

//   ${breakpoint('medium')} {
//     width: 50%;
//   }

//   ${breakpoint('mediumlarge')} {
//     width: 33.333%;
//   }

//   ${breakpoint('large')} {
//     width: 25%;
//   }
// `;

function Contact() {
  // useLangRedirect(page);

  return (
    <Layout>
      <SEO title="Kontakt" />

      <Hero
        heading="Kontakt"
        // image={featuredMedia?.localFile}
        pageTitle
        // preHeading="Pre heading"
        // actions="Actions"
        tint
      />

      <Container>
        <Row>
          <Col>
            <SectionTitle>
              Frågor eller vill du erbjuda publikfigurer till din klubb?
            </SectionTitle>
            <p>
              Kontakta oss på{' '}
              <Link to="mailto:publikfigur.se@gmail.com">
                publikfigur.se@gmail.com
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Contact;
